div {
  box-sizing: border-box;
}

.table-container {
  color: black;
  display: block;
  margin: 2em auto;

  border-radius: 10px;
}

.flex-table {
  display: grid;
  grid-template-columns: 15% 35% 15% 15%;
  grid-template-rows: 100% auto;
  justify-content: center;
  border: solid 1px #d9d9d9;
  transition: 0.5s;
}
.flex-table:first-of-type {
  /* border-top: solid 1px #1565c0;
  border-left: solid 1px #1565c0; */
}
.flex-table:first-of-type .flex-row {
  color: var(--text-color);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  text-align: center;
  border: none;
  /* border-color: #1565c0; */
}
.flex-table.row:nth-child(even) .flex-row {
  background: #f7f6fe;
}
/* .flex-table:hover {
  background: #f5f5f5;
  transition: 500ms;
} */

.flex-row {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 0.75em 0.5em;
  border-right: solid 1px #d9d9d9;
  border-bottom: solid 1px #d9d9d9;
}

.rowspan {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-rows: 100%;
}

.column {
  width: 100%;
  padding: 0;
}
.column .flex-row {
  display: grid;
  grid-template-columns: repeat(auto-fill, 33.3%);
  grid-template-rows: 100% 100% 100%;
  width: 100%;
  padding: 0;
  border: 0;
  border-bottom: solid 1px #d9d9d9;
}
.column .flex-row:hover {
  background: #f5f5f5;
  transition: 500ms;
}

.flex-cell {
  text-align: center;
  padding: 0.5em 0.5em;
  border: solid 1px black;
}

.table-exhibit-container {
  font-size: 26px;
}

@media all and (min-width: 431px) and (max-width: 768px) {
  .flex-table {
    display: grid;
    grid-template-columns: repeat(auto-fill, 33.33%);
    grid-template-rows: repeat(auto-fill, 100%);
  }
  .flex-table .flex-row {
    border-bottom: solid 1px #d9d9d9;
  }

  .rowspan {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: repeat(auto-fill, 100%);
  }
  .rowspan .flex-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, 33.33%);
    border-bottom: solid 1px #d9d9d9;
  }
  .rowspan p {
    margin: 0;
  }

  .first {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  .rowspan > .first {
    display: block;
  }
}
@media all and (max-width: 430px) {
  .flex-table {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: 100% auto;
  }
  .flex-table .flex-row {
    border-bottom: 0;
  }
  .flex-table .flex-row:last-of-type {
    border-bottom: solid 1px #d9d9d9;
  }

  .first {
    border-bottom: 0;
    grid-column-start: auto;
    grid-column-end: auto;
  }

  .rowspan {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: 100% auto;
  }
  .rowspan .flex-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, 100%);
    grid-template-rows: auto;
    border-bottom: solid 1px #d9d9d9;
  }
  .rowspan p {
    margin: 0;
  }
}

@media all and (max-width: 1120px) {
  .flex-table {
    grid-template-columns: 19% repeat(auto-fill, 27%);
  }
}

.table-container.mobile-agency {
  width: 95%;
  border-radius: 10px;
  padding-bottom: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.first-cell-container {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  justify-content: space-evenly;
  width: 100%;
}

.table-wrap {
  background-color: white;
  padding: 50px;
}

.table-btn-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.first-cell-container.mobile-agency {
  text-align: center;
  background-color: #f7f6fe;
  padding: 20px 20px;
}

.table-header.mobile-agency {
  font-weight: bold;
}

.applicant-container.mobile-agency {
  background-color: #f7f6fe;
  padding: 10px 10px;
  gap: 10px;
}

.applicant-btn.mobile-agency {
  margin: 5px;
}

.desktop {
  display: none !important;
}

@media all and (min-width: 750px) {
  .mobile {
    display: none !important;
  }

  .desktop {
    display: initial !important;
  }
}
