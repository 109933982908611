.image-container {
  position: relative;
  display: inline-block;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 18px; /* Adjust font size */
  line-height: 1; /* Prevent additional height */
}

.close-button:hover {
  background-color: darkred;
}
