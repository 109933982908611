.App {
  text-align: center;
}

.App-header {
  background-color: white;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  font-size: 26px;
  color: darkslategray;
  padding-top: 30px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.new-exhibit-form {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 60px 20px;
}

.input-error {
  color: red;
}

.react-select {
  color: black;
}

input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none !important;
  border-bottom: 1px solid darkslategray !important;
  color: darkslategray !important;
  background: white;
}

.select-input {
  text-decoration: none;
  font-style: none;
}

.select-input input {
  border-bottom: none !important;
}

.job-form-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin: 10px 0;
}

.job-form-input {
  display: flex;
  width: 100%;
  gap: 15px;
  align-items: center;
}

.job-form-input.sqft {
  width: 60%;
}

#startDate {
  width: 32%;
}

.job-form-input-container.error,
.property-type-container.error,
.setting-type-container.error,
.space-type-container.error {
  border: solid 3px red;
  padding: 5px;
  border-radius: 10px;
}

.job-form-input input {
  flex-grow: 1;
}

.agree-check {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 10px 0 20px 0;
}

.agree-check span.checkmark {
  margin-top: 50%;
}

.terms-p {
  width: 60%;
  align-self: center;
}

.city-state-zip,
.startdate-term {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.startdate-term {
  justify-content: space-between;
}

.startdate-term .job-form-input {
  justify-content: flex-end;
}

.city-state-zip .job-form-input input,
.startdate-term .job-form-input input {
  width: 25%;
}

.city-state-zip .job-form-input #city,
.startdate-term .job-form-input #startDate {
  flex-grow: 0.5;
}

.startdate-term .job-form-input:first-of-type {
  justify-content: flex-start;
}

.startdate-term .job-form-input .select-input {
  min-width: 275px;
}

/* Customize the label (the container) */
.radio-container {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: whitesmoke;
  border: solid lightgray 1px;
  border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.radio-container .checkmark:after {
  left: 9px;
  top: 0;
  width: 10px;
  height: 20px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.property-type-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.checkbox-outer-container {
  display: flex;
  margin-top: 15px;
  gap: 30px;
  width: 100%;
  justify-content: space-between;
}

.check-header-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 35px;
}

.checkbox-header {
  margin: 0;
  font-style: italic;
  text-decoration: underline;
  font-weight: bold;
}

.space-extra {
  margin-left: 5px;
  font-size: 16px;
  align-self: flex-end;
  text-decoration: none !important;
}

.upload-header {
  margin-top: 60px;
}

.exhibit-submit {
  margin-top: 50px;
  align-self: center;
}

.logo {
  max-width: 300px;
}

.navbar {
  padding: 40px;
  display: flex;
  background-color: black;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  justify-content: center;
}

.nav-container,
.nav-ul {
  display: flex;
  list-style: none;
  width: 75%;
}

.nav-ul {
  display: flex;
  justify-content: center;
}

.nav-item {
  color: white;
  text-decoration: none;
  width: 100px;
}

.nav-btn {
  background-color: rgb(0, 235, 255);
  color: black;
  border: none;
  border-radius: 10px;
  width: 200px;
  font-weight: 600;
}

.login-link {
  color: white;
  justify-self: flex-end;
  align-self: center;
  margin-left: auto;
}

.dashboard {
  width: 100%;
  padding: 50px 10px;
  margin-top: -1.2%;
  /* background-color: rgb(67, 89, 104); */
  background-color: white;
}

.title-btn-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.title-btn-wrap,
h2 {
  width: 100%;
  justify-self: center;
}

.dashboard-btn,
.partner-dash-btn,
.exhibit-submit,
.login-btn,
.exhibit-save {
  background-color: rgb(93, 135, 160);
  border: none;
  color: white;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 16px;
}

.dashboard-btn.fresh {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: initial;
  padding: 15px;
  width: 400px;
  font-size: 32px;
  margin: 40px auto;
  transition: all ease 0.3s;
}

.dashboard-btn.fresh:hover {
  background-color: rgb(80, 117, 138);
  transform: translateY(-3px);
}

.login-btn {
  width: 150px;
  font-size: 20px;
  margin: 30px 0;
}

.add-partner-btn,
.new-exhibit-btn {
  position: absolute;
  right: 2%;
}

.add-partner-btn {
  top: -220px;
}

.new-exhibit-btn {
  top: -170px;
}

.partner-dash-btn {
  font-size: 22px;
  width: 300px;
  font-weight: bold;
  color: whitesmoke;
}

.exhibit-submit,
.exhibit-save {
  margin-top: 50px;
  align-self: center;
  font-size: 22px;
  width: 300px;
  font-weight: bold;
  color: whitesmoke;
  margin-bottom: 30px;
  transition: all ease 0.3s;
}

.exhibit-save {
  background-color: #ccc;
  color: black;
}

.exhibit-submit:hover {
  background-color: rgb(80, 117, 138);
  transform: translateY(-3px);
}

.exhibit-save:hover {
  background-color: #afafaf;
  transform: translateY(-3px);
}

.office-logo {
  width: 20%;
  position: absolute;
  left: 0;
  top: 150px;
}

.disclaimer-check {
  width: 80%;
  margin-top: 50px;
  text-align: left;
}

.disclaimer-check .job-form-input-radio-check .checkmark {
  margin-top: 20px;
}

.forgot-pass-text {
  font-size: 16px;
}

.form-group .login-element input {
  border: 1px solid black !important;
  border-radius: 5px;
  background-color: whitesmoke;
  margin-left: 10px;
  padding-left: 10px;
}

.form-group .login-element input:hover {
  background-color: lightgray;
  transition: all ease 0.3s;
}

.submit-review-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}

.company-name {
  font-size: 36px;
  font-style: italic;
}

.button-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(128, 128, 128, 0.2);
  backdrop-filter: blur(3px);
}

.my-modal {
  background-color: black;
  color: whitesmoke;
  padding: 20px;
  height: 300px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
}

.modal-btn-cont {
  display: flex;
  justify-content: space-around;
}

.modal-delete,
.modal-cancel {
  background-color: rgb(93, 135, 160);
  border: none;
  color: white;
  border-radius: 10px;
  padding: 8px 15px;
  font-size: 16px;
  align-self: center;
  font-size: 22px;
  width: 200px;
  font-weight: bold;
  color: whitesmoke;
  transition: all ease 0.3s;
}

.modal-delete {
  background-color: darkred;
}

.posted-btn {
  font-size: 24px;
  color: black;
}

.view-exhibit-button {
  color: blue;
}

.lost-forever,
.remove-exhibit-button {
  color: red;
}

.review-container {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.pdf-viewer-container {
  max-width: 1010px;
}

.inner-rev-container {
  justify-content: space-around;
  display: flex;
  width: 100%;
}

.review-h2 {
  font-family: Poppins, sans-serif;
  position: absolute;
  top: 175px;
  font-size: 48px;
}

.retrn-btn {
  margin-bottom: 80px;
}

.image-edit-wrap {
  padding: 5px;
  border: 1px solid black;
  margin: 5px auto 20px auto;
  border-radius: 5px;
  background: whitesmoke;
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1%;
  row-gap: 10px;
}

.image-review {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 1%;
  row-gap: 10px;
}

.image-review img {
  width: 31%;
}

.image-review-container {
  width: 30%;
}

.submit-review-form .submit-btn {
  margin-top: 0;
  margin-bottom: 100px;
}

.image-container {
  max-width: 200px;
}

.image-container img {
  max-width: 200px;
}

.exhibit-form-container.edit-form {
  max-width: 1000px;
}

.modal-p {
  font-size: 16px;
}

.error-message {
  color: red;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.error-message span {
  display: inline-flex;
  align-items: center;
}

.my-tooltip {
  position: absolute;
  top: calc(-100% - 100px);
  transform: translate(-100%, -20%);
  background-color: rgba(243, 243, 243, 1);
  color: black;
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
  width: 30vw;
  border: 2px solid black;
  box-shadow: 4px 8px 8px hsl(0deg 0% 0% / 0.38);
  text-align: left;
}

.my-tooltip-container {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 1px;
  margin: 0;
  padding: 0;
}
