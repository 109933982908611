.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  z-index: 1000; /* Ensure it overlays above other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 50%;
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-around; /* Space buttons evenly */
  margin-top: 10px;
  line-height: 1;
}

.modal-content h4 {
  margin: 0 0 10px;
}

.modal-content p {
  margin: 0 0 15px;
}

.modal-content button {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.cancel-modal,
.delete-modal {
  width: 100px;
  height: 50px;
}

.cancel-modal {
  background-color: darkgray;
  color: white;
}

.delete-modal {
  background-color: red;
  color: white;
}

.cancel-modal:hover {
  background-color: gray;
}

.delete-modal:hover {
  background-color: darkred;
}

p.no-modal-text {
  font-size: 12px;
  align-self: flex-end;
  margin: 0;
  margin-right: auto;
}
